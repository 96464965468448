(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

require('./modules/_parallax.js');
require('./modules/_scroller.js');
require('./modules/_vue_contact.js');

var App = {};

$(function () {

    App.$window = $(window);
    App.$body = $('body');

    $('.main-navbar a, .scroll-nav').click(function (e) {
        e.preventDefault();

        App.$window.scrollTo($(this).attr('href'), {
            duration: 500
        });
    });

    // Scroll to top
    if ($.fn.pageScroller) {
        $('.scroll-to-top').pageScroller();
    }

    if ($.fn.parallax) {
        $('#home').parallax({
            speed: 0.05,
            direction: -1
        });
    }

    $('.product-slider').slick({
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right"></i></button>',
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left"></i></button>',
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        }, {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }, {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    });

    $('.testimonials-slider, .service-slider').slick({
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right"></i></button>',
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left"></i></button>'
    });

    $('.team-slider').slick({
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right"></i></button>',
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left"></i></button>',
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }, {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }, {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    });

    // Set active scroll class to body
    (function (App) {
        'use strict';

        var active = false;

        App.$window.scroll(function () {
            if (App.$window.scrollTop() > 50) {
                if (!active) {
                    App.$body.addClass('active-scroll');
                    active = true;
                }
            } else {
                if (active) {
                    App.$body.removeClass('active-scroll');
                    active = false;
                }
            }
        });
    })(App);
});

},{"./modules/_parallax.js":2,"./modules/_scroller.js":3,"./modules/_vue_contact.js":4}],2:[function(require,module,exports){
'use strict';

/**
 * Simple paralax effect
 */
(function ($) {
    'use strict';

    $.fn.parallax = function (options) {
        var windowHeight = $(window).height(),
            settings = $.extend({
            speed: 0.15,
            direction: 1
        }, options);

        return this.each(function () {
            var $this = $(this);

            $(document).scroll(function () {
                var scrollTop = $(window).scrollTop(),
                    offset = $this.offset().top,
                    height = $this.outerHeight();

                if (offset + height <= scrollTop || offset >= scrollTop + windowHeight) {
                    return;
                }

                var yBgPosition = Math.round((offset - scrollTop) * settings.speed) * settings.direction;

                $this.css('background-position', 'center ' + yBgPosition + 'px');
            });
        });
    };
})(jQuery);

},{}],3:[function(require,module,exports){
'use strict';

/**
 * Page scroller
 */
(function ($) {
    'use strict';

    $.fn.pageScroller = function (options) {
        var settings = $.extend({
            speed: 300,
            activeClass: 'visible'
        }, options);

        function Scroller(btn, settings) {
            var _this = this,
                $btn = $(btn);

            this.scrollTop = function () {
                $('body, html').animate({
                    scrollTop: 0
                }, settings.speed);
            };

            $(window).scroll(function () {
                $btn.toggleClass(settings.activeClass, $(this).scrollTop() > 0);
            });

            $btn.click(function (e) {
                e.preventDefault();

                _this.scrollTop();
            });
        }

        return this.each(function () {
            new Scroller(this, settings);
        });
    };
})(jQuery);

},{}],4:[function(require,module,exports){
'use strict';

new Vue({
    el: '#vue_contact',

    data: {
        submitting: false,

        message: {
            name: '',
            email: '',
            message: ''
        }
    },

    /**
     * Once the component is ready to be shown
     **/
    ready: function ready() {},

    /**
     * Defined methods
     **/
    methods: {

        /**
         * Handle the submitting form
         **/
        onSubmitForm: function onSubmitForm(e) {
            e.preventDefault();

            this.submitting = true;

            var message = this.message;

            this.$http.post('/ua/message', message, {
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            }).then(function (responce) {
                this.$set('message', {
                    name: '',
                    email: '',
                    message: ''
                });
            }, function (error) {
                alert('Error');
            }).finally(function () {
                this.$set('submitting', false);
            });

            return false;
        }
    },

    /**
     * Computed properties
     **/
    computed: {

        /**
         * Check if data ready to be sent
         **/
        isDisabled: function isDisabled() {

            for (var key in this.message) {
                if (!this.message[key]) {
                    return true;
                }
            }

            return false;
        }
    }
});

},{}]},{},[1]);
